import { Box, Button, Grid, Link, Typography } from '@mui/material'
import React from 'react'


const AboutRecity = () => {
  return (
    <Grid container paddingTop={2} width={"100%"} justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Box display={'flex'} flexDirection={"row"} >

        <Typography color={"gray"} paddingInline={2} variant='h5'>About
        </Typography>
        <img width={80} height={30} src='/assets/recity_name.png' alt='recity Logo'/>   
        </Box>
        <Typography color={"gray"} padding={1} paddingBottom={4} variant='p'>
        Recity is a circular waste management company, working
        closely with city governments to streamline a city's waste
        management, in alignment with Swachh Bharat Mission.
        Founded in 2017, Recity was born with a dream to solve a
        multitude of urban issues by bringing citizens and various other
        stakeholders together to develop livable cities. Today, Recity is
        present across 22+ Indian cities and has prevented over 55,000+
        Tonnes of waste from entering the environment.
          </Typography>
       
        <Typography marginBottom={2} color={"gray"} variant='h5'>To visit our website</Typography>
        <Button  href='https://www.recity.in'  variant="contained">Click here</Button>
    </Grid>
  )
}

export default AboutRecity

