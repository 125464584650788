import { Box, Checkbox, Container, Typography } from '@mui/material'
import React, { useState } from 'react'

const StaticCards = () => {
  const [disable, setDisable] = useState(false)
  const handleChanged = (e) =>{
    // takePledge();
    // setPledgeCount(pledgeCount + 1)
    setDisable(true)
    // console.log(e.target.checked);
  }
  return (
    <Box sx={{background:"#23418D"}}>
        <img width={"100%"} src='/assets/EisbBanner1.png' alt='recity Logo' /> 
        <img width={"100%"} src='/assets/EisbBanner2.png' alt='recity Logo' /> 
        <img width={"100%"} src='/assets/EisbBanner3.png' alt='recity Logo' /> 
        <Box display={"flex"} marginBlock={2} flexDirection={"row"}>
        <Checkbox  size='large' 
         onChange={e => {
          handleChanged(e) 
        }}
        disabled={disable}
        style ={{
                      color: "#00e676",
                    }}
                    
                    />
        <Typography variant='h5' paddingTop={1} color={"white"}>I pledge to make responsible choices going ahead!</Typography>
        </Box>
    </Box>
  )
}

export default StaticCards