
import { Box, Button, Container, Grid } from '@mui/material';
import './App.css';
import Header from './Components/Header/Header';
import HeroSection from './Components/Header/HeroSection';
import Details from './Components/Main-Components/Details';
import DidYouKnow from './Components/Main-Components/DidYouKnow';
import AboutRecity from './Components/Main-Components/AboutRecity';
import Social from './Components/Main-Components/Social';
import GamePage from './Components/Main-Components/GamePage';
import ScratchCardPage from './Components/Game/ScratchCard';
import RegistratioForm from './Components/EISB/RegistrationForm/RegistratioForm';
import StaticCards from './Components/EISB/StaticInformation/StaticCards';

function App() {
  return (
    <Grid container justify="center" alignItems="center" direction="column" >
      <Header />
      <HeroSection />
      <RegistratioForm />
      <StaticCards />
      {/* <Details/> */}
      {/* <DidYouKnow /> */}
      {/* <GamePage /> */}
      <AboutRecity />
      <Social />
    </Grid>
    );
}

export default App;
