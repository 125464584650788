import { Box, Button, Container, FormControl, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react';
import useEisbData from '../data-fetch/eisb-data-fetch';

const RegistratioForm = () => {
  const ref = useRef(null)
  const {registerUser} = useEisbData();
  const [userType, setUserType] = useState("student")
  const handleSubmit = async (e)=>{
    e.preventDefault();
    
    let body = {};

    if(userType === "student"){
      body = {
        name: e.target.name.value,
        mobileNo: e.target.mobile.value,
        email: e.target.email.value,
        userType : userType
      }
    }else{
      body = {
        name: e.target.name.value,
        mobileNo: e.target.mobile.value,
        email: e.target.email.value,
        organisation: e.target.organisation.value,
        userType : userType
      }
    }
   const result = await registerUser(JSON.stringify(body));
   if(result.code === 200){
    const alertString = userType === "student" ? "Thank you for registering for Second Chance. You will receive the certificate shortly." : "Thank you for registering for Second Chance." 
    alert(alertString);
    window.location.reload();
   }
    

  }
  return (
   <Box >
    <Box>
      <Button variant="outlined"  sx={{width: "45%", padding:1, margin:1, borderColor: userType === "student" ? "blue" :"gray"}} onClick={()=>setUserType("student")}>
          <Typography>Student</Typography>
        </Button>
        <Button variant="outlined"  sx={{width: "45%", padding:1, margin:1, borderColor: userType === "parents" ? "blue" :"gray"}}onClick={()=>setUserType("parents")}>
          <Typography>Parents</Typography>
        </Button>
    </Box>
    <form  onSubmit={(e)=>handleSubmit(e)}>
      <TextField ref={ref} sx={{width: "95%", margin:1}} variant="outlined" label="Name" type='text' name='name' required /> 
      <TextField sx={{width: "95%", margin:1}} variant="outlined" label="Mobile Number" type='text' name='mobile' required /> 
      {userType === "student" ? <TextField sx={{width: "95%", margin:1}} variant="outlined" label="Email" type='email' name='email'  /> : <TextField sx={{width: "95%", margin:1}} variant="outlined" label="Email" type='email' name='email' required /> }
      {
        userType === "parents" ?
        <TextField sx={{width: "95%", margin:1}} variant="outlined" label="Organisation" type='text' name='organisation'  /> : ""
      }
      <Button variant="contained" type="submit" sx={{width: "95%", padding:1, margin:1}}>
        <Typography>Submit</Typography>
      </Button>
    </form>
   </Box>
  )
}

export default RegistratioForm