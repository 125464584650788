

import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL

const useEisbData =()=>{
    
    // const [pledgeCount, setPledgeCount] = useState(0);

    // useEffect(()=>{
    //     (async()=>{
    //         const res = await fetch(BASEURL + "bin-cleanup/plegdes", {
    //             // body: p_body,
    //             mode: "cors",
    //             headers: {
    //             //   Authorization: `Bearer ${token}`,
    //               "Content-Type": "application/json",
    //             },
    //             method: "GET",
    //           });
    //           const result = await res.json();
    //           setPledgeCount(result?.data?.count)
    //         //   console.log("pledge count",pledgeCount)
    //     })()
    // },[])
  
      
    const registerUser = async (p_body)=>{
      
        const res = await fetch(BASEURL + "eisb", {
            body: p_body,
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          });
          const result = await res.json();
          
        //   console.log(result)
          return result
    }

    
    return {registerUser}
}

export default useEisbData;